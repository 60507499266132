<template>
  <div @click.stop="onClick" :class="['item', { selected: active }]">
    <div class="data-wrapper">
      <div class="data-part map-name">
        {{ data.mapName }}
      </div>
      <div class="data-part chart-type">
        {{ ChartTypeTranslation[data.chartType] }}
      </div>
      <div v-show="isDataShown" class="more-data--wrapper">
        <div class="data-part">
          <span class="text-line">
            {{ data.firstPersonData.date }},
            {{ data.firstPersonData.time | cutSeconds }}
          </span>
          <br />
          <div class="text-line" v-html="getPlace(data.firstPersonData)" />
        </div>
        <div class="data-part" v-if="data.secondPersonData">
          <span class="text-line">
            {{ data.secondPersonData.date }},
            {{ data.secondPersonData.time | cutSeconds }}
          </span>
          <br />
          <div class="text-line" v-html="getPlace(data.secondPersonData)" />
        </div>
        <div class="data-btns">
          <button class="edit-btn" @click.stop="editItem(data)">
            <img :src="require('@/assets/icons/pencil.svg')" alt="edit" />
            <span>{{ $t('change') }}</span>
          </button>
          <button class="favorite-btn" @click.stop="deleteItem(data)">
            <img :src="require('@/assets/icons/trash.svg')" alt="favourite" />
            <span>{{ $t('delete') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="item-icon" @click.stop="isDataShown = !isDataShown">
      <img
        :class="['shevron', { down: isDataShown }]"
        :src="require('@/assets/icons/arrow-down-blue.svg')"
        alt="show data"
        height="8px"
        width="12px"
      />
    </div>
  </div>
</template>
<script>
import { ChartTypeTranslation } from '@/types';
import { mapActions } from 'vuex';

export default {
  name: 'ChartItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDataShown: false,
      ChartTypeTranslation,
    };
  },
  computed: {
    getPlace() {
      return (params) => params.place || `${params.lat}, <br> ${params.long}`;
    },
  },
  methods: {
    ...mapActions('ChartModule', ['queueDeleteHistoryItem']),
    onClick() {
      this.$emit('selected', this.data);
    },
    onDelete(item) {
      this.queueDeleteHistoryItem(item);
    },
    deleteItem(item) {
      this.$emit('showDelete', item);
    },
    editItem(item) {
      this.$emit('edit-current', item);
    },
  },
};
</script>
<style lang="sass" scoped>
.item
  position: relative
  display: flex
  flex-direction: row
  align-content: space-between
  justify-content: space-between
  align-items: flex-start
  width: 100%
  box-sizing: border-box
  padding: base-unit(10) base-unit(10) base-unit(10)
  cursor: pointer
  border: base-unit(1) solid grey-color('10')
  color: grey-color('500')
  font-size: base-unit(12)
  font-weight: 400
  border-radius: base-unit(10)
  margin-bottom: base-unit(10)
  +astro-box-shadow('small', rgba(0, 0, 0, 0.075))
  +medium-devices
    width: base-unit(170)
    width: 100%
    font-size: base-unit(14)
    padding: base-unit(20)
  +large-devices
    width: base-unit(256)
    width: 100%
    font-size: base-unit(16)
    padding: base-unit(27)

  &:hover,
  &.selected
    border: base-unit(1) solid blue-color('primary')
    +medium-devices
      padding: base-unit(19)
    +large-devices
      padding: base-unit(26)
  &-icon
    width: base-unit(36)
    height: base-unit(36)
    border-radius: 50%
    background: #EDF4F7
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075)
    display: flex
    justify-content: center
    align-items: center
.data-wrapper
  display: flex
  flex-direction: column
  align-items: flex-start
.check-icon
  position: absolute
  left: base-unit(2)
  top: base-unit(2)
  +medium-devices
    top: base-unit(6)
    left: base-unit(144)
  +large-devices
    top: base-unit(12)
    left: base-unit(224)
.data-wrapper
  display: flex
  justify-content: space-between
.data-part
  text-align: left
  color: grey-color('500')
  font-size: base-unit(16)
  &:not(:last-child)
    margin-bottom: base-unit(12)
    +medium-devices
      margin-bottom: base-unit(14)

.chart-type
  font-size: base-unit(16)
  color: grey-color('400')
  +large-devices
    font-size: base-unit(14)

.data-part.map-name
  font-size: base-unit(16)
  margin-bottom: base-unit(4)
.data-part.chart-type
  margin-bottom: base-unit(4)
  color: grey-color('500')

.more-data--wrapper
  display: flex
  flex-direction: column
  align-items: flex-start
.text-line
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.shevron.down
  transform: rotate(180deg)

.data-btns
  display: flex
  align-items: center
  column-gap: base-unit(10)
  span
    color: grey-color('500')
    font-size: base-unit(16)
  img
    width: base-unit(12)
    height: base-unit(12)
    margin-right: base-unit(4)
  .edit-btn
    display: flex
    align-items: center
    text-decoration: none
  .favorite-btn
    display: flex
    align-items: center
</style>
